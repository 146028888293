import http from '../../http';

const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const getAbout = () =>{
    return(dispatch, getState) => {

        // http.post('page/about-page', {accion:""}, vHeader)
        http.post('pages/about/about.php', {accion:""}, vHeader)
        .then(function (response){
            //console.log(response);
            dispatch({                
                type: "ABOUT_DATA_SUCCESS", payload: {
                    data: response.data.resultado,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'ABOUT_DATA_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        }); 
    }
}