import React,{useState, useCallback, useEffect} from 'react';

import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";

import http from '../../http';

const PopupMagnific = () => {
    
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const [photos, setPhotos] = useState([]);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	useEffect(()=>{
		http.get('pages/gallery/gallery.php')
		.then((response) => {
			setPhotos(response.data.resultado.gallery);
		})
	},[]);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};

	// const photos = [
	// 	{
	// 	  src: 'https://api.brotherscharters.com/images/RxHPBTj6NPPOBl4s.jpeg',
	// 	  width: 1,
	// 	  height: 1
	// 	},
	// 	{
	// 	  src: 'https://api.brotherscharters.com/images/k7zWRHwSHLtTk0Si.jpeg',
	// 	  width: 1,
	// 	  height: 1
	// 	},
	// 	{
	// 		src: 'https://api.brotherscharters.com/images/RxHPBTj6NPPOBl4s.jpeg',
	// 		width: 1,
	// 		height: 1
	// 	  },
	// 	  {
	// 		src: 'https://api.brotherscharters.com/images/k7zWRHwSHLtTk0Si.jpeg',
	// 		width: 1,
	// 		height: 1
	// 	  }
	//   ];

    return (
		<div>
			{
				photos && (
				<>
					<Gallery photos={photos} onClick={openLightbox} />
					<ModalGateway>
						{viewerIsOpen ? (
						<Modal onClose={closeLightbox}>
							<Carousel
								showArrows={true}
								currentIndex={currentImage}
								views={photos.map(x => ({
									...x,
									srcset: x.srcSet,
									caption: x.title
								}))}
							/>
						</Modal>
						) : null}
					</ModalGateway>
				</>
				)
			}
			
		</div>
	);
}

export default PopupMagnific;