const initState = {
    about_Data: null,
    about_Error_Message: null,
    loading: null
}

const aboutReducer = (state = initState, action) => {
    switch (action.type) {        
        case "ABOUT_DATA_SUCCESS":
            return {
                ...state,
                about_Data: action.payload.data,
                about_Error_Message: null
            }
        
        case "ABOUT_DATA_ERROR":
            return {
                ...state,                
                about_Error_Message: action.payload.error,
                about_Data: null,
            }
        
        default:
            return state;
    }
};


export default aboutReducer;