import React from 'react'
import PropTypes from 'prop-types';

import video_adventuremp4 from '../../assets/video/adventuree.mp4';

import parse from 'html-react-parser';

const VideoIntro = ({ description }) =>{

    return(
        <div className="bg_color_1">
            <div className="container container-custom margin_60_0">
                <div className="main_title_2">
                    <span><em></em></span>
                    <h2>Brother's Charters</h2>
                    <p>Sport Fishing Los Cabos</p>
                </div>
                <div className="row">
                    <div className="col-lg-6" >
                        <div className="embed-responsive embed-responsive-16by9" style={{boxShadow: '3px 3px 3px 3px #888888', marginBottom:'25px'}}>
                            <video autoPlay={true} loop="loop" muted className="embed-responsive-item" src={video_adventuremp4}></video>
                        </div>
                    </div>
                    
                    <div className="col-lg-6" >
                        { parse(description) }
                    </div>
                </div>
            </div>
        </div>
    )
}

VideoIntro.propTypes = {
    description: PropTypes.string.isRequired
}

export default VideoIntro;