import React from 'react';
import { Carousel } from 'react-bootstrap';

import PropTypes from 'prop-types';

const Slider = ({jsonCarousel}) =>{

    // console.log(jsonCarousel)
    
    return(
        <>
            <div id="full-slider-wrapper">
                <Carousel fade>
                    {
                        jsonCarousel.map( (item, idx) => {
                            return <Carousel.Item key={ idx }>
                                <img
                                    className="d-block w-100"
                                    src={ item.image }
                                />
                            </Carousel.Item>
                        })
                    }
                </Carousel>
            </div>
        </>
    );
}

Slider.propTypes = {
    jsonCarousel: PropTypes.array.isRequired
}

export default Slider;