import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";

import { getHome } from '../../store/actions/homeActions';

import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import Slider from '../Shared/Slider';
// import Banner from '../Shared/Banner';

import SharedRentNow from '../Shared/SharedRentNow';
import LuxuryRent from '../Shared/LuxuryRent';
import VideoIntro from './VideoIntro';

const Home = (props) => {
    
    const [welcome, setWelcome] = useState("");
    const [slider, setSlider] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        localStorage.setItem('idRentNow', '');
        localStorage.setItem('type', '');
        props.getHomeData();
    }, []);

    useEffect(() => {
        if(props.Data !== null) {
            setWelcome(props.Data.welcome[0].description);
            setSlider(props.Data.slider);
        }
    }, [props.Data]);

    return(
        <div id="page">

            <Header />

            <main className="maincontainer">
                <Slider jsonCarousel={ slider !== undefined ? slider : [] } />
                
                <VideoIntro description={ welcome === "" ? "" : welcome } />

                <SharedRentNow titulo="Rent Now" subtitulo="" tipo="carousel" />

                <LuxuryRent titulo="Luxury Yatchs" subtitulo="" />
            </main>
            <Footer/>            
            
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        "Data": state.home.home_Data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "getHomeData": () => dispatch(getHome())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);