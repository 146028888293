import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";

import {getAbout} from '../../store/actions/aboutActions';
import parse from 'html-react-parser';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const About = (props) =>{
    const [textPrincipal, setTextPrincipal] = useState("");
    const [textTeo, setTextTeo] = useState("");
    const [textRolando, setTextRolando] = useState("");
    const [textH3, setTextH3] = useState("");
    const [imgTeo, setImgTeo] = useState("");
    const [imgRolando, setImgRolando] = useState("");
    const [imgBrothers, setImgBrothers] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        props.getAbout();        
    }, []);

    useEffect(() => {
        if(props.Data!==null){
            // console.log(props.Data.data);
            setTextPrincipal(props.Data.data.texto1);
            setTextTeo(props.Data.data.texto2);
            setTextRolando(props.Data.data.texto3);
            setTextH3(props.Data.data.texto4);
            setImgTeo(props.Data.data.img_teo);
            setImgRolando(props.Data.data.img_rolando);
            setImgBrothers(props.Data.data.img_brothers);
        }
    }, [props.Data]);

    return(
        <div id="page">
            <Header />            
                <main>
                    <section className="hero_in contacts">
                        <div className="wrapper">
                            <div className="container">
                                <h1 className="fadeInUp"><span></span>About Brother's Charters</h1>
                            </div>
                        </div>
                    </section>

                    <div className="bg_color_1">
                        <div className="container margin_80_55">
                            <div className="main_title_2">
                                <span><em></em></span>
                                <h2>Brother's Charters</h2>
                                <p>Sport Fishing Los Cabos</p>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-lg-5" data-wow-offset="150">
                                    <img src={ imgBrothers } className="img-fluid shadow-img" alt="" />
                                </div>
                                <div className="col-lg-7">
                                    {parse(textPrincipal)}
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-lg-8">
                                    <br />
                                    {parse(textTeo)}
                                </div>
                                <div className="col-lg-4" data-wow-offset="150">
                                    <img src={ imgTeo } className="img-fluid shadow-img" alt="" />
                                </div>
                            </div>
                            <hr />
                            <div className="row justify-content-between">
                                <div className="col-lg-4" data-wow-offset="150">
                                    <img src={ imgRolando } className="img-fluid shadow-img" alt="" />
                                </div>
                                <div className="col-lg-8">
                                    <br />
                                    {parse(textRolando)}
                                </div>
                            </div>
                            <hr />
                            <div className="row justify-content-between">
                                <div className="col-lg-12">{parse(textH3)}</div>
                            </div>
                        </div>
                    </div>
                </main>                
            <Footer/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        "Data": state.about.about_Data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "getAbout": () => dispatch(getAbout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);