import React from 'react';

import adventure_1 from '../../assets/img/adventure_latest_1.jpg';

const LastAddAdventure = () =>{

    return(
        <>
            <section>
                <div className="main_title_3">
                    <span><em></em></span>
                    <h2>Last Added Adventures Tours</h2>
                    <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6">
                        <a href="restaurant-detail.html" className="grid_item latest_adventure">
                            <figure>
                                <div className="score"><strong>8.5</strong></div>
                                <img src={adventure_1} className="img-fluid" alt="" />
                                <div className="info">
                                    <em>2 days in United States</em>
                                    <h3>Canyoning El paso</h3>
                                </div>
                            </figure>
                        </a>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6">
                        <a href="restaurant-detail.html" className="grid_item latest_adventure">
                            <figure>
                                <div className="score"><strong>7.9</strong></div>
                                <img src={adventure_1} className="img-fluid" alt="" />
                                <div className="info">
                                    <em>2 days in Canada</em>
                                    <h3>Camping and mountains</h3>
                                </div>
                            </figure>
                        </a>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6">
                        <a href="restaurant-detail.html" className="grid_item latest_adventure">
                            <figure>
                                <div className="score"><strong>7.5</strong></div>
                                <img src={adventure_1} className="img-fluid" alt="" />
                                <div className="info">
                                    <em>1 days in United States</em>
                                    <h3>Route 66 Bike Riding</h3>
                                </div>
                            </figure>
                        </a>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6">
                        <a href="restaurant-detail.html" className="grid_item latest_adventure">
                            <figure>
                                <div className="score"><strong>9.0</strong></div>
                                <img src={adventure_1} className="img-fluid" alt="" />
                                <div className="info">
                                    <em>2 days Belize</em>
                                    <h3>San Rafael Belize</h3>
                                </div>
                            </figure>
                        </a>
                    </div>
                    
                </div>
                
                <a href="#0"><strong>View all (157) <i className="arrow_carrot-right"></i></strong></a>
            </section>
        </>
    );
}

export default LastAddAdventure;