import React from 'react';

const Banner = ({ Category, Title1, Title2, Description, Url }) =>{

    Category = "";
    Title1 = "Sport Fishing Los Cabos";
    Title2 = "Get a 5% off if you pay cash";
    Description = "Adventure";
    Url = "adventure";

    return(
        <>
            {/* mb-0 */}
            <div className="banner">
                <div className="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                    <div>
                        <small>{ Category }</small>
                        <h3>{ Title1 }<br />{ Title2 }</h3>
                        {/* <p>{Description}</p> */}
                        {/* <a href="adventure.html" className="btn_1">Read more</a> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner;