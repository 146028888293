import React from 'react';

import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const PichBook = () =>{
    
    const url = process.env.REACT_APP_API_BASE_URL;

    return (
      <div id="page">

            <Header />

            <main>
              <section className="hero_in contacts" style={{height:'200px'}}>
                <div className="wrapper">
                  <div className="container">
                    <h1 className="fadeInUp"><span></span>PICH BOOK</h1>
                  </div>
                </div>
              </section>
              <div className="row">
                <div className="col-lg-2">
                  &nbsp;
                </div>
                <div className="col-lg-8" style={{height:"800px"}}>
                  <iframe src={`${url}pdf/100.pdf`} width="100%" height="100%"></iframe>
                </div>
                <div className="col-lg-2">
                  &nbsp;
                </div>
              </div>
            </main>
            <Footer/>            
            
      </div>
    )
}

export default PichBook;
