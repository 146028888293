import React from 'react';

const Hotel = () =>{
    return(
        <>
            <h1>Hotel.js</h1>
        </>
    );
}

export default Hotel;