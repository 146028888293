const initState = {
    home_Data: null,
    home_Slide: null,
    home_Error_Message: null,
    loading: null
}

const homeReducer = (state = initState, action) => {
    switch (action.type) {
        case "HOME_DATA_SUCCESS":
            return {
                ...state,
                home_Data: action.payload.data,
                home_Error_Message: null
            }
        break;
        case "HOME_DATA_ERROR":
            return {
                ...state,                
                home_Error_Message: action.payload.error,
                home_Data: null,
            }
        break;
        case "HOME_SLIDE_SUCCESS":
            return {
                ...state,
                home_Slide: action.payload.data,
                home_Error_Message: null
            }
        break;
        case "HOME_SLIDE_ERROR":
            return {
                ...state,                
                home_Error_Message: action.payload.error,
                home_Slide: null,
            }
        break;
        
        default:
            return state;
    }
};


export default homeReducer;