import React,{ useEffect } from 'react';
import $ from 'jquery';

import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const WhatsApp = (props) => {
    useEffect(() => {
        $("._YLx_9").css({
            right:'0px',
            bottom:'55px'
        });
        
        $("._1bpcM").css({
            fontSize:'3em'
        });

    }, []);

    return(
        <WhatsAppWidget phoneNumber={props.whatsapp} companyName = {props.empresa} textReplyTime = {'Typically replies within a hour'}/>
    )
}

export default WhatsApp;