import http from '../../http';

const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const getContact = () =>{
    return(dispatch, getState) => {

        // http.post('page/contacts-page', {accion:""}, vHeader)
        http.post('pages/about_us/about_us.php', {accion:""}, vHeader)
        .then(function (response){
            dispatch({
                type: "CONTACT_DATA_SUCCESS", payload: {
                    data: response.data.resultado,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'CONTACT_DATA_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        }); 
    }
}

export const sendMail = (name, email, phone, message) =>{
    return (dispatch, getState) => {
        let parametros = JSON.stringify({name, email, phone, message });
        
        http.post('pages/about_us/email.php',parametros,vHeader)
        // http.post('page/sendmail-page',parametros,vHeader)
        .then(response => {
            let type = ((response.data.codigo == 200) ? 'CONTACT_SEND_SUCCESS' : 'CONTACT_SEND_ERROR');
            dispatch({ type: type, payload: { data: response.data.mensaje }});
        })
        .catch(error => {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'CONTACT_SEND_ERROR', payload: { error: errorMessage } });
        });
    }
}

export const closeMessages = () => {
    return (dispatch, getState) => {
        dispatch({type: "CONTACT_CLOSE_MESSAGES"});
    }
}