import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

import imgAbout from '../../assets/img/contact_map.jpg';
import { getContact, sendMail, closeMessages } from '../../store/actions/contactActions';

import SweetAlert from "react-bootstrap-sweetalert";

import { validateEmail } from '../Recursos/Recursos';

const Contacts = (props) => {

    const [nameContact, setNameContact] = useState("");
    const [emailContact, setEmailContact] = useState("");
    const [phoneContact, setPhoneContact] = useState("");
    const [messageContact, setMessageContact] = useState("");
    const [nameContactError, setNameContactError] = useState("");
    const [emailContactError, setEmailContactError] = useState("");
    const [phoneContactError, setPhoneContactError] = useState("");
    const [messageContactError, setMessageContactError] = useState("");
    const [messageSendEmail, setMessageSendEmail] = useState("");
    const [messageSendEmailError, setMessageSendEmailError] = useState("");

    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        props.getContact();
    }, [])

    useEffect(() => {
        if(props.Data!==null){
            
            setAddress(props.Data.data.address);
            setEmail(props.Data.data.email);
            setPhone(props.Data.data.phone);
        }
    }, [props.Data]);

    useEffect(() => {
        if(props.successMessage) {
            setMessageSendEmail(props.successMessage);
        }
    },[props.successMessage]);
    
    useEffect(() => {
        if(props.errorMessage) {
            setMessageSendEmailError("An error occurred sending email");
        }
    },[props.errorMessage]);

    const onHandleSend = () =>{
        let bool = true;
        if(nameContact===""){
            setNameContactError("Name is required");
            bool = false;
        }else{
            setNameContactError("");
        }
        if(emailContact===""){
            setEmailContactError("Email is required");
            bool = false;
        }else{
            if(!validateEmail(emailContact)){
                setEmailContactError("Email is invalid");
                bool = false;
            }else{
                setEmailContactError("");
            }
        }
        if(phoneContact===""){
            setPhoneContactError("Cellphone is required");
            bool = false;
        }else{
            setPhoneContactError("");
        }
        if(messageContact===""){
            setMessageContactError("Message is required");
            bool = false;
        }else{
            setMessageContactError("");
        }

        if(bool){
            setDisableButton(true);
            props.sendMail(nameContact,emailContact,phoneContact,messageContact);
        }
    }

    const hideAlert = (cleanForm) => {
        props.closeMessages();
        setDisableButton(false);

        if(cleanForm){
            setNameContact("");
            setNameContactError("");
            setEmailContact("");
            setEmailContactError("");
            setPhoneContact("");
            setPhoneContactError("");
            setMessageContact("");
            setMessageContactError("");

            setTimeout(()=>{
                setMessageSendEmail("");
                setMessageSendEmailError("");
            },5000);
        }
    };

    let successMessage = !props.successMessage ? null : (
        <SweetAlert
          success
          style={{ display: "block" }}
          title = { props.successMessage }
          onConfirm={() => hideAlert(true)}
          onCancel={() => hideAlert(true)}
         />
    );
    
    let errorMessage = !props.errorMessage ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => hideAlert(false)}
          onCancel={() => hideAlert(false)}
        >
          {props.errorMessage}
        </SweetAlert>
    );

    return(
        
        <div id="page">
            {successMessage}
            {errorMessage}
      
            <Header />
            <main>
                <section className="hero_in contacts">
                    <div className="wrapper">
                        <div className="container">
                            <h1 className="fadeInUp"><span></span>Contact Us</h1>
                        </div>
                    </div>
                </section>
                
                <div className="contact_info">
                    <div className="container">
                        <ul className="clearfix">
                            <li>
                                <i className="pe-7s-map-marker"></i>
                                <h4>Address</h4>
                                <span>{address}</span>
                            </li>
                            <li>
                                <i className="pe-7s-mail-open-file"></i>
                                <h4>Email address</h4>
                                <span>{email}</span>

                            </li>
                            <li>
                                <i className="pe-7s-phone"></i>
                                <h4>Contacts info</h4>
                                <span>{phone}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="bg_color_1">
                    <div className="container margin_80_55">
                        <div className="row justify-content-between">
                            
                                <div className="col-lg-5">
                                    <div className="map_contact">
                                        <img src={ imgAbout } className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h4>Send a message</h4>
                                    <div id="message-contact"></div>
                                    <form method="post" action="assets/contact.php" id="contactform">
                                    <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>* Name</label>
                                                    <input className="form-control" type="text" onChange={(e) => setNameContact(e.target.value)} value={nameContact} autoComplete="new-password" />
                                                    {
                                                        nameContactError !== "" && <small style={{color:'red'}}>{nameContactError}</small>
                                                    }                                                
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>* Email</label>
                                                    <input className="form-control" type="email" onChange={(e) => setEmailContact(e.target.value)} value={emailContact} autoComplete="new-password" />
                                                    {
                                                        emailContactError !== "" && <small style={{color:'red'}}>{emailContactError}</small>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>* Cellphone</label>
                                                    <input className="form-control" type="text" onChange={(e) => setPhoneContact(e.target.value)} value={phoneContact} autoComplete="new-password" />
                                                    {
                                                        phoneContactError !== "" && <small style={{color:'red'}}>{phoneContactError}</small>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group">
                                            <label>* Message</label>
                                            <textarea className="form-control" style={{ height:'150px', resize:'none' }} onChange={(e) => setMessageContact(e.target.value)} value={messageContact}></textarea>
                                            {
                                                messageContactError !== "" && <small style={{color:'red'}}>{messageContactError}</small>
                                            }
                                        </div>
                                        <p className="add_top_30">
                                            <input type="button" value="Send Message" className="btn_1 rounded" onClick={onHandleSend} disabled={disableButton} />
                                            {
                                                messageSendEmail !== "" && <span style={{color:'blue'}}>{messageSendEmail}</span>
                                            }
                                            {
                                                messageSendEmailError !== "" && <span style={{color:'red'}}>{messageSendEmailError}</span>
                                            }
                                        </p>
                                    </form>
                                </div>
                            
                        </div>
                        
                    </div>
                    
                </div>

            </main>
            <Footer/>            
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        "Data": state.contact.contact_Data,        
        "successMessage": state.contact.contact_Success_Message,
        "errorMessage": state.contact.contact_Error_Message,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "getContact": () => dispatch(getContact()),
        "sendMail": (name, email, phone, message) => dispatch(sendMail(name, email, phone, message)),
        "closeMessages": () => dispatch(closeMessages()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);