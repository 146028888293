import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import PreLoader from './PreLoader';

import Logo from '../../assets/img/logo.svg';

const Header = () =>{

    const [openHamburger, setOpenHamburger] = useState(false);

    const handleHamburger = () =>{
        let show = !openHamburger;
        setOpenHamburger(show);
    }

    return(
        <>
            <header className="header menu_fixed">
                <PreLoader />
                
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar">
                    <div className="container">
                        <div id="logo">
                            <NavLink className="nav-link" to="/">
                                <img src={ Logo } width="150" height="36" data-retina="true" alt="" className="logo_normal" />
                                <img src={ Logo } width="150" height="36" data-retina="true" alt="" className="logo_sticky" />
                            </NavLink>
                        </div>
                        <a className="navbar-brand"><strong>&nbsp;</strong></a>
                        <button className="navbar-toggler" type="button" onClick={handleHamburger}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className={`${!openHamburger && 'collapse'} navbar-collapse`} id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">                                
                                <li className="nav-item">
                                    <NavLink exact activeClassName="active" className="nav-link" to="/" >Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact activeClassName="active" className="nav-link" to="/rentlist">Rent Now</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact activeClassName="active" className="nav-link" to="/luxurylist">Luxury Yatchs</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact activeClassName="active" className="nav-link" to="/gallery">Gallery</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact activeClassName="active" className="nav-link" to="/book">Pich Book</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact activeClassName="active" className="nav-link" to="/about">About</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink exact activeClassName="active" className="nav-link" to="/contacts">Contact us</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;