import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";

import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

import './style.css';

import { getRentDetail, getRentLuxuryDetail } from '../../store/actions/rentActions';

import StickyEmail from '../Shared/StickyEmail';
import BoatClassic from './BoatClassic';
import BoatLuxury from './BoatLuxury';

const RentNow = (props) => {
    const [ type, setType ] = useState("");
    const [ titulo, setTitulo ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ image, setImage ] = useState("");
    const [ isOk, setIsOk] = useState(false);

    useEffect(()=>{
        setType(localStorage.getItem('type'));
        window.scrollTo(0, 0);
        if(localStorage.getItem('type') == 'Luxury'){
            props.getRentLuxuryDetail(localStorage.getItem('idRentNow'));
        } else {
            props.getRentDetail(localStorage.getItem('idRentNow'));
        }        
    },[]);

    useEffect(()=>{
        if(props.Data) {
            setTitulo(props.Data.detail.titulo);
            setDescription(props.Data.detail.descripcion);
            setImage(props.Data.detail.imagen);
            setIsOk(true);
        } 
    },[props.Data]);

    return(

        <div id="page" className="theia-exception">
            
            <Header />
            
            <main>
                <section className="hero_in contacts">
                    <div className="wrapper">
                        <div className="container">
                            <h1 className="fadeInUp"><span></span>RENT NOW</h1>
                        </div>
                    </div>
                </section>
                <br />
                <div className="bg_color_1">
                    <div className="main_title_2">
                        <span><em></em></span>
                        <h2>{titulo}</h2>
                        <p>Rent Now</p>
                    </div>
                    <div id="description" className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row justify-content-between">
                                    <div className="col-lg-12 text-center" data-wow-offset="150">
                                        <img src={ image } className="img-fluid" alt="" style={{boxShadow: '3px 3px 3px 3px #888888'}} />
                                    </div>
                                </div>
                                <hr />
                                {
                                    (isOk) ?
                                        (type == 'Clasic') 
                                        ? <BoatClassic Data={props.Data} description={description}></BoatClassic>
                                        : <BoatLuxury Data={props.Data} ></BoatLuxury>
                                    :
                                    null
                                }
                                
                            </div>
                            <aside className="col-lg-4" id="sidebar">
                                <StickyEmail></StickyEmail>
                            </aside>
                                
                        </div>
                    </div>
                </div>
            </main>
           
            <Footer/>            
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        "Data": state.rent.rent_Data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "getRentDetail": (id) => dispatch(getRentDetail(id)),        
        "getRentLuxuryDetail": (id) => dispatch(getRentLuxuryDetail(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RentNow);
