import React from 'react';

const BoatClassic = (props) => {
   console.log(props.Data);
    // props = props === undefined ? {} : props.Data;

    const NumberFormat = (valor) => new Intl.NumberFormat('en-US').format(valor);

    const Anglers = props.Data && (
        props.Data.packages.map((result,id) => {
            return <div key={id} className="col-md-5 isotope-item popular">
                <div className="box_grid_rent">
                    <figure style={{ height:'50px', backgroundColor:"#3b6998" }}>
                        <h3 style={{ fontSize: '24px', padding: `10px 0px 0px 20px`, color:"white" }}>PACKAGES <label style={{ fontStyle: `italic`, color: `#7ae114` }}>UP TO {result.angler}</label></h3>
                    </figure>
                    <div className="wrapperr">
                        <ul>
                            <li>
                                <i className="icon_clock_alt"></i>&nbsp;{ result.horario1 }
                            </li>
                            <li>
                                <i className="icon-dollar"></i>&nbsp;{ NumberFormat(result.precio1) } USD
                            </li>
                        </ul>
                        {
                            result.horario2 != null && <ul>
                                <li>
                                    <i className="icon_clock_alt"></i>&nbsp;{ result.horario2 }
                                </li>
                                <li>
                                    <i className="icon-dollar"></i>&nbsp;{ result.precio2 > 0 ? NumberFormat(result.precio2) + ' USD' : <i className="icon-cancel-3" style={{color:"red"}}></i> }                                       
                                </li>
                            </ul>
                        }
                    </div>
                </div>
            </div>
        })
    );

    const Equipment = props.Data && (
        props.Data.equipment.map((result,id) => {
            let obj = result.equipo.split("|");

            let valor = <div key={id} className="col-lg-6">
                <h3 className="Titulos">{result.tipo}</h3>
                <ul className="bullets">
                {
                    obj.map((val, i)=>{
                        return <li key={i}>{ val }</li>
                    })
                }
                </ul>
            </div>
            
            return valor;
        })
    );

    return(
        <React.Fragment>
            <section>
                <div className="row">
                    {
                        Equipment
                    }                                        
                </div>
                <hr />
                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="Titulos">ALL INCLUSIVE</h3>
                        <ul className="bullets">
                            <li>Fishing Licenses</li>
                            <li>Fee access</li>
                            <li>1 Box lunch p/p (Turkey, Tuna or Burritos)</li>
                            <li>6 Drinks p/p (Bottle water, Soda Pop, Mexican beer)</li>
                            <li>Live bait (subject to availability)</li>
                            <li>Filleting</li>
                            <li>Round trip transportation Hotel-Marina-Hotel</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 text-center">
                        <h4>WHAT'S NOT INCLUDED</h4>
                        <h4>GRATUITIES FOR THE CREW</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="container container-custom margin_60_0">
                        <div className="main_title_2">
                            <span><em></em></span>
                            <h2 className="Titulos">PACKAGES</h2>
                            <p>{ props.description }</p>
                            <h5>* ALL TAXES INCLUDED *</h5>
                        </div>
                    </div>
                </div>
                    
                <div className="row">{ Anglers }</div>
                
            </section>
        </React.Fragment>
    );
}

export default BoatClassic;