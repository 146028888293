const initState = {
    rent_Data: null,
    rent_Error_Message: null,
    rent_List_Data:null,
    rent_List_Error_Message:null,
    luxury_List_Data:null,
    luxury_List_Error_Message:null,
    loading: null
}

const rentReducer = (state = initState, action) => {
    switch (action.type) {
        case "RENT_DATA_SUCCESS":
            return {
                ...state,
                rent_Data: action.payload.data,
                rent_Error_Message: null
            }
        
        case "RENT_DATA_ERROR":
            return {
                ...state,                
                rent_Error_Message: action.payload.error,
                rent_Data: null,
            }
        case "RENT_LIST_SUCCESS":
            return {
                ...state,
                rent_List_Data: action.payload.data,
                rent_List_Error_Message: null
            }
        
        case "RENT_LIST_ERROR":
            return {
                ...state,                
                rent_List_Error_Message: action.payload.error,
                rent_List_Data: null,
            }
        case "LUXURY_LIST_SUCCESS":
            return {
                ...state,
                luxury_List_Data: action.payload.data,
                luxury_List_Error_Message: null
            }
        
        case "LUXURY_LIST_ERROR":
            return {
                ...state,                
                luxury_List_Error_Message: action.payload.error,
                luxury_List_Data: null,
            }
        case "CLEAN_LIST_SUCCESS":
            return {
                ...state,
                rent_List_Data: null,
                rent_List_Error_Message: null
            }

        default:
            return state;
    }
};


export default rentReducer;