const initState = {    
    contact_Data: null,
    contact_Error_Message: null,
    contact_Success_Message: null,
    contact_Data_Send:null,
    loading: null
}

const contactReducer = (state = initState, action) => {
    switch (action.type) {
        case "CONTACT_DATA_SUCCESS":
            return {
                ...state,
                contact_Data: action.payload.data,
                contact_Error_Message: null
            }
        
        case "CONTACT_DATA_ERROR":
            return {
                ...state,                
                contact_Error_Message: action.payload.error,
                contact_Data: null,
            }
        case "CONTACT_SEND_SUCCESS":
            return {
                ...state,
                contact_Success_Message: action.payload.data,
                contact_Error_Message: null
            }
        
        case "CONTACT_SEND_ERROR":
            return {
                ...state,                
                contact_Error_Message: action.payload.data,
                contact_Success_Message: null,
            }
        case "CONTACT_CLOSE_MESSAGES":

            return {
                ...state,
                contact_Success_Message:null,
                contact_Error_Message:null
            }
        default:
            return state;
    }
};


export default contactReducer;