import React, {useEffect} from 'react';

import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import LuxuryRent from '../Shared/LuxuryRent';

const LuxuryList = () =>{

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div id="page" className="theia-exception">
            <Header />
            <main>
                <section className="hero_in contacts">
                    <div className="wrapper">
                        <div className="container">
                            <h1 className="fadeInUp"><span></span>RENT LUXURY YACHT NOW</h1>
                        </div>
                    </div>
                </section>
                <div className="maincontainer">
                    <div className="isotope-wrapper">
	                    <div className="row">
                            <LuxuryRent titulo="Luxury Yatchs" subtitulo="" />
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default LuxuryList;