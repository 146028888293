import React, { useEffect } from 'react';
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import { getListadoLuxuryRent } from '../../store/actions/rentActions';

const LuxuryRent = (props) =>{

	const history = useHistory();

	useEffect(() => {
        props.getListadoLuxuryRent();
    }, []);

	const onClickRent = (e, id) => {
        e.preventDefault();
		localStorage.setItem('idRentNow', id);
		localStorage.setItem('type', 'Luxury');
		history.push('/rent');
	}

    const getBoxList = (item) => {
        return <div key={ "div_" + item.id } className="col-xl-4 col-lg-6 col-md-6">
            <a href="#" onClick={(e)=>{ onClickRent(e, item.id) }} className="grid_item latest_adventure">
                <div className="box_grid">
                    <figure>
                        <img src={ item.imagen } className="img-fluid" alt="" style={{cursor:'pointer'}} /><div className="read_more"><span>Read more</span></div>
                        <div className="info">
                            <em>3 Hours</em>
                            <h3>{ item.titulo }</h3>
                        </div>
                    </figure>
                </div>
            </a>
        </div>
    }

    const Listado = props.ListData == null ? <div className="item"></div> : (
        props.ListData.map((item) => { 
            return getBoxList(item)
        })
    );

    return(
       <>
            <div className="container container-custom margin_60_0">
                <div className="main_title_2">
                    <span><em></em></span>
                    <h2>{ props.titulo }</h2>
                    <p>{ props.subtitulo }</p>
                </div>
                <div className="row">
                    {
                        Listado
                    }
                </div>
            </div>
	   </>        
    );

}

LuxuryRent.propTypes = {
	titulo: PropTypes.string.isRequired, 
	subtitulo: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    return {
        "ListData": state.rent.luxury_List_Data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "getListadoLuxuryRent": () => dispatch(getListadoLuxuryRent()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LuxuryRent);