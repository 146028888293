import React from 'react';

import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import VideoAdventure from './VideoAdventure';
import CarouselAdventure from './CarouselAdventure';
import Banner from '../Shared/Banner';
import LastAddAdventure from './LastAddAdventure';

//Images
import adventure_icon_1 from '../../assets/img/adventure_icon_1.svg';
import adventure_icon_2 from '../../assets/img/adventure_icon_2.svg';
import adventure_icon_3 from '../../assets/img/adventure_icon_3.svg';

const Adventure = () =>{

    return(
        <div id="page">
            <Header />            
                <main>                    
                    <VideoAdventure />
                    <div className="container container-custom margin_80_55">
                        <CarouselAdventure />
                        <Banner />
                        <LastAddAdventure />

                        <div className="bg_color_1">
                            <div className="container container-custom margin_80_55">
                                <div className="main_title_2">
                                    <h2>Plan Your Trip Easly</h2>
                                    <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
                                </div>
                                <div className="row adventure_feat">
                                    <div className="col-md-4">
                                        <img src={adventure_icon_1} alt="" width="75" height="75" />
                                        <h3>Itineraries studied in detail</h3>
                                        <p>Ius cu tamquam persequeris, eu veniam apeirian platonem qui, id aliquip voluptatibus pri.</p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={adventure_icon_2} alt="" width="75" height="75" />
                                        <h3>Room and food included</h3>
                                        <p>His in harum errem dissentias, has mutat facilisi ea, ubique possim praesent eum ea.</p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src={adventure_icon_3} alt="" width="75" height="75" />
                                        <h3>Everything organized</h3>
                                        <p>In ridens tamquam argumentum usu, ne ludus intellegebat vix. Eu inani omnes usu, an pri errem mucius.</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="call_section adventure">
                            <div className="container clearfix">
                                <div className="col-lg-5 col-md-6 float-right wow" data-wow-offset="250">
                                    <div className="block-reveal">
                                        <div className="block-vertical"></div>
                                        <div className="box_1">
                                            <h3>Enjoy a GREAT travel with us</h3>
                                            <p>Ius cu tamquam persequeris, eu veniam apeirian platonem qui, id aliquip voluptatibus pri. Ei mea primis ornatus disputationi. Menandri erroribus cu per, duo solet congue ut. </p>
                                            <a href="#0" className="btn_1 rounded">Read more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </main>
            <Footer/>
        </div>
    );
}

export default Adventure;