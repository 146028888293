import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { getContact } from '../../store/actions/contactActions';

import { NavLink } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Logo from '../../assets/img/logo.svg';

import WhatsApp from './WhatsApp';

const Footer = (props) =>{

    const [facebook, setFacebook] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [company, setCompany] = useState("");
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {        
        props.getContact();        
    }, []);

    useEffect(() => {
        if(props.Data!==null) {            
            setCompany(props.Data.data.nombre_empresa);
            setFacebook(props.Data.data.facebook);
            setPhone(props.Data.data.phone);
            setEmail(props.Data.data.email);
            setWhatsapp(props.Data.data.whatsapp);
        }
    }, [props.Data]);

    return(
        <>
            {
                whatsapp !== "" && <WhatsApp whatsapp={whatsapp} empresa={company} />
            }
            <footer>
            <div className="container margin_60_35">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 p-r-5">
                            <p><img src={ Logo } width="150" height="36" data-retina="true" alt="" /></p>
                            {/* <p>Mea nibh meis philosophia eu. Duis legimus efficiantur ea sea. Id placerat tacimates definitionem sea, prima quidam vim no. Duo nobis persecuti cu. Nihil facilisi indoctum an vix, ut delectus expetendis vis.</p> */}
                            <div className="follow_us">
                                <ul>
                                    <li>Follow us</li>
                                    <li><a href={facebook} target="_blank"><i className="ti-facebook"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ml-lg-auto">
                            <h5>Useful links</h5>
                            <ul className="links">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/rentlist">Rent Now</NavLink></li>
                                <li><NavLink to="/luxurylist">Rent Luxury Yacht Now</NavLink></li>
                                <li><NavLink to="/about">About</NavLink></li>                                
                                <li><NavLink to="/contacts">Contact us</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6">
                           <h5>Contact with Us</h5>
                            <ul className="contacts">
                                <li><a href={`tel://${whatsapp}`}><i className="ti-mobile"></i> { phone }</a></li>
                                <li><a href= {`mailto:${email}`}><i className="ti-email"></i> { email }</a></li>
                            </ul>
                        </div>
                    </div>
                    {/* End Row */}
                    <hr/>
                    <div className="row">
                        <div className="col-lg-6">
                            <ul id="footer-selector">
                                <li></li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <ul id="additional_links">
                                <li><span>© {currentYear} {company}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollToTop />	
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        "Data": state.contact.contact_Data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "getContact": () => dispatch(getContact())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);