import React, { useEffect } from 'react';
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';

import { getListadoRent, deleteListadoRent } from '../../store/actions/rentActions';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const SharedRentNow = (props) =>{

	const history = useHistory();

	useEffect(() => {
        props.getListadoRent();
    }, []);

	const onClickRent = (id) =>{
		localStorage.setItem('idRentNow', id);
        localStorage.setItem('type', 'Clasic');
		history.push('/rent');
	}

    const getBoxList = (item) => {
        return <div className="col-xl-4 col-lg-6 col-md-6 isotope-item popular">
            <div className="box_grid">
                <figure>
                    <img onClick={()=>{ onClickRent(item.id) }} src={ item.imagen } className="img-fluid" alt="" width="800" height="533" style={{cursor:'pointer'}} /><div className="read_more" style={{cursor:'pointer'}} onClick={()=>{ onClickRent(item.id) }}><span>Read more</span></div>
                </figure>
                <div className="wrapper">
                <h3><a href="#" onClick={()=>{ onClickRent(item.id) }}>{ item.titulo }</a></h3>
                    <p>{ item.descripcion }</p>
                    {
                        parseFloat(item.precio) > 0 && <span className="price">From <strong>{ item.precio }</strong> /per person</span>
                    }
                </div>
                <ul>
                    <li><i className="icon_clock_alt"></i> { item.duracion }</li>
                    <li><div className="score"><strong>{ item.raiting }</strong></div></li>
                </ul>
            </div>
        </div>
    }

    const Listado = props.ListData == null ? <div className="item"></div> : (
        props.ListData.map((item) => { 
            return getBoxList(item)
        })
    );

	const carousel = props.ListData == null ? <div className="item"></div> : (
		props.ListData.map((item, i) => {
			return <div key={ i } className="item">
				<div className="box_grid">
					<figure>
						<img onClick={()=>{ onClickRent(item.id) }} src={ item.imagen } className="img-fluid" alt="" width="800" height="533" style={{cursor:'pointer'}} /><div className="read_more" style={{cursor:'pointer'}} onClick={()=>{ onClickRent(item.id) }}><span>Read more</span></div>
					</figure>
					<div className="wrapper">
						<h3><a href="#" onClick={()=>{ onClickRent(item.id) }}>{ item.titulo }</a></h3>
						<p>{ item.descripcion }</p>
                        {
                            parseFloat(item.precio) > 0 && <span className="price">From <strong>{ item.precio }</strong> /per person</span>
                        }
					</div>
					<ul>
						<li><i className="icon_clock_alt"></i> { item.duracion }</li>
						<li><div className="score"><strong>{ item.raiting }</strong></div></li>
					</ul>
				</div>
			</div>;
		})
	);    

    return(
       <>
            {
                props.tipo === "carousel" ?             
                <div key={props.titulo} className="container container-custom margin_60_0">
                        <div className="main_title_2">
                            <span><em></em></span>
                            <h2>{ props.titulo }</h2>
                            <p>{ props.subtitulo }</p>
                        </div>
                        <div id="reccomended">
                            {
                                props.ListData != null ?
                            
                            <OwlCarousel
                                className="owl-carousel owl-theme"
                                center= {true}
                                items={2}
                                loop
                                margin={0}
                                responsive= {{
                                    0: {
                                        items: 1
                                    },
                                    767: {
                                        items: 2
                                    },
                                    1000: {
                                        items: 3
                                    },
                                    1400: {
                                        items: 4
                                    }
                                }}
                            >
                                {
                                    carousel
                                }
                            </OwlCarousel>
                            
                            : null
                            }
                        </div>
                        {/* <p className="btn_home_align"><a href="tours-grid-isotope.html" className="btn_1 rounded">View all Tours</a></p>
                        <hr className="large"></hr> */}
                </div>

                :
                   <div className="row">
                    { 
                       Listado
                    }
                   </div>
            }
	   </>        
    );

}

SharedRentNow.propTypes = {
	titulo: PropTypes.string.isRequired, 
	subtitulo: PropTypes.string.isRequired, 
    tipo: PropTypes.string
}


const mapStateToProps = (state) => {
    return {
        "ListData": state.rent.rent_List_Data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "getListadoRent": () => dispatch(getListadoRent()),
        "deleteListadoRent" : () => dispatch(deleteListadoRent()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedRentNow);