import React from 'react';

const BoatLuxury = (props) => {
    console.log(props);
    const Equipment = props.Data && (
        props.Data.specifications.map((result,id) => {
            let obj = result.equipo.split("|");

            let valor = <div key={id} className="col-lg-6">
                <h3 className="Titulos">{result.tipo}</h3>
                <ul className="bullets">
                {
                    obj.map((val, i)=>{
                        return <li key={i}>{ val }</li>
                    })
                }
                </ul>
            </div>
            
            return valor;
        })
    );

    return(
        <React.Fragment>
            <section>
                <div className="row">
                    <div className="container container-custom">
                        <div className="main_title_2">
                            <h5>* ALL TAXES INCLUDED *</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        Equipment
                    }                                        
                </div>
            </section>
            <br />
        </React.Fragment>
    );
}

export default BoatLuxury;