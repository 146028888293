import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";

import StickyBox from "react-sticky-box";

import { sendMail, closeMessages } from '../../store/actions/contactActions';
import SweetAlert from "react-bootstrap-sweetalert";
import { validateEmail } from '../Recursos/Recursos';

const StickyEmail = (props) => {
    const [nameContact, setNameContact] = useState("");
    const [emailContact, setEmailContact] = useState("");
    const [phoneContact, setPhoneContact] = useState("");
    const [messageContact, setMessageContact] = useState("");
    const [nameContactError, setNameContactError] = useState("");
    const [emailContactError, setEmailContactError] = useState("");
    const [phoneContactError, setPhoneContactError] = useState("");
    const [messageContactError, setMessageContactError] = useState("");
    const [messageSendEmail, setMessageSendEmail] = useState("");
    const [messageSendEmailError, setMessageSendEmailError] = useState("");

    const [disableButton, setDisableButton] = useState(false);
    
    useEffect(() => {
        if(props.successMessage) {
            setMessageSendEmail(props.successMessage);
        }
    },[props.successMessage]);
    
    useEffect(() => {
        if(props.errorMessage) {
            setMessageSendEmailError("An error occurred sending email");
        }
    },[props.errorMessage]);

    const onHandleSend = () => {
        let bool = true;
        if(nameContact===""){
            setNameContactError("Name is required");
            bool = false;
        }else{
            setNameContactError("");
        }
        if(emailContact===""){
            setEmailContactError("Email is required");
            bool = false;
        }else{
            if(!validateEmail(emailContact)){
                setEmailContactError("Email is invalid");
                bool = false;
            }else{
                setEmailContactError("");
            }
        }
        if(phoneContact===""){
            setPhoneContactError("Cellphone is required");
            bool = false;
        }else{
            setPhoneContactError("");
        }
        if(messageContact===""){
            setMessageContactError("Message is required");
            bool = false;
        }else{
            setMessageContactError("");
        }        

        if(bool){
            setDisableButton(true);
            props.sendMail(nameContact,emailContact,phoneContact,messageContact);
        }
    }

    const hideAlert = (cleanForm) => {
        props.closeMessages();
        setDisableButton(false);

        if(cleanForm){
            setNameContact("");
            setNameContactError("");
            setEmailContact("");
            setEmailContactError("");
            setPhoneContact("");
            setPhoneContactError("");
            setMessageContact("");
            setMessageContactError("");

            setTimeout(()=>{
                setMessageSendEmail("");
                setMessageSendEmailError("");
            },5000);
        }
    };

    let successMessage = !props.successMessage ? null : (
        <SweetAlert
          success
          style={{ display: "block" }}
          title = { props.successMessage }
          onConfirm={() => hideAlert(true)}
          onCancel={() => hideAlert(true)}
         />
    );
    
    let errorMessage = !props.errorMessage ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error sending email!"
          onConfirm={() => hideAlert(false)}
          onCancel={() => hideAlert(false)}
        >
          {props.errorMessage}
        </SweetAlert>
    );

    return(
        <>
            {successMessage}
            {errorMessage}
            <StickyBox offsetTop={100} offsetBottom={20} >
                <div className="box_detail booking">
                    <div className="price">
                        <span>Contact us</span>
                    </div>

                    <div className="form-group">
                        <input className="form-control" type="text" placeholder="* Name" onChange={ e => setNameContact(e.target.value) } value={nameContact} />
                        <i className="ti-user"></i>
                        {
                            nameContactError !== "" && <small style={{color:'red'}}>{nameContactError}</small>
                        }
                    </div>
                    <div className="form-group">
                        <input className="form-control" type="text" placeholder="* Email" onChange={ e => setEmailContact(e.target.value) } value={emailContact} />
                        <i className="icon_mail_alt"></i>
                        {
                            emailContactError !== "" && <small style={{color:'red'}}>{emailContactError}</small>
                        }
                    </div>
                    <div className="form-group">
                        <input className="form-control" type="text" placeholder="* CellPhone" onChange={ e => setPhoneContact(e.target.value) } value={phoneContact} />
                        <i className="icon_phone"></i>
                        {
                            phoneContactError !== "" && <small style={{color:'red'}}>{phoneContactError}</small>
                        }
                    </div>
                    <div className="form-group">
                        <textarea placeholder="* Your message" className="form-control" style={{ height:'100px' }} onChange={ e => setMessageContact(e.target.value) } value={messageContact}></textarea>
                        <i className="ti-pencil"></i>
                        {
                            messageContactError !== "" && <small style={{color:'red'}}>{messageContactError}</small>
                        }
                    </div>
                    <input type="button" className="btn_1 full-width purchase" onClick={onHandleSend} disabled={disableButton} value="Contact us" />
                    {
                        messageSendEmail !== "" && <span style={{color:'blue'}}>{messageSendEmail}</span>
                    }
                    {
                        messageSendEmailError !== "" && <span style={{color:'red'}}>{messageSendEmailError}</span>
                    }
                </div>
            </StickyBox>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        "successMessage": state.contact.contact_Success_Message,
        "errorMessage": state.contact.contact_Error_Message
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "sendMail": (name, email, phone, message) => dispatch(sendMail(name, email, phone, message)),
        "closeMessages": () => dispatch(closeMessages())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StickyEmail);