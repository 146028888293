import React from 'react';
import $ from 'jquery';

import Logo from '../../assets/img/logo_completo.svg';

import './styles.css';

const PreLoader = () =>{

    React.useEffect(() =>
        {
            // setTimeout(()=>{
                $('#prelogo').fadeOut();
                $('#preloader').delay(350).fadeOut('slow');
            // },200)
            
        },[]
    );

    return(
        <>
            <div id="preloader" style={{ textAlign:"center", backgroundColor:"#252526" }}>
                <img id="prelogo" src={Logo} width="30%" style={{ marginTop:"5em" }} />                
            </div>
        </>
    )
}

export default PreLoader;