import React from 'react';
import $ from 'jquery';

const ScrollToTop = () => {

  const handleClick = () =>{
    $('body,html').animate({
      scrollTop: 0
    }, 500);
  };

  return(
    <div id="toTop" onClick = { () => handleClick()  } ></div>
  );
}

export default ScrollToTop;