import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import tour_1 from '../../assets/img/adventure_popular_1.jpg';

const CarouselAdventure = () =>{

    let jsonItem = {
		titulo: "Our Popular Tours",	
		subtitulo: "Cum doctus civibus efficiantur in imperdiet deterruisset.",
		items:	[
				{ 
					titulo:"3 days in Patagonia", 
					descripcion:"Horseback ride through Valencia", 
					tipo: "Historic",
					precio:"$54",
					duracion:"1h 30min",
					imagen:tour_1
				},
				{ 
					titulo:"Olas Altas", 
					descripcion:"Id placerat tacimates definitionem sea.", 
					tipo: "Culture",
					precio:"$54",
					duracion:"1h 30min",
					imagen:tour_1
				},
				{ 
					titulo:"Clavadista", 
					descripcion:"Id placerat tacimates definitionem sea.", 
					tipo: "Culture",
					precio:"$54",
					duracion:"1h 30min",
					imagen:tour_1
				},
				{ 
					titulo:"Monos Bichis", 
					descripcion:"Id placerat tacimates definitionem sea.", 
					tipo: "Culture",
					precio:"$54",
					duracion:"1h 30min",
					imagen:tour_1
                },
                { 
					titulo:"Monos Bichis", 
					descripcion:"Id placerat tacimates definitionem sea.", 
					tipo: "Culture",
					precio:"$54",
					duracion:"1h 30min",
					imagen:tour_1
                },
                { 
					titulo:"Monos Bichis", 
					descripcion:"Id placerat tacimates definitionem sea.", 
					tipo: "Culture",
					precio:"$54",
					duracion:"1h 30min",
					imagen:tour_1
                },
                { 
					titulo:"Monos Bichis", 
					descripcion:"Id placerat tacimates definitionem sea.", 
					tipo: "Culture",
					precio:"$54",
					duracion:"1h 30min",
					imagen:tour_1
				}
			]
	};

    return(
        
            
                <section className="add_bottom_45">
                    <div className="main_title_3">
                        <span><em></em></span>
                        <h2>{ jsonItem.titulo }</h2>
                        <p>{ jsonItem.subtitulo }</p>
                    </div>
                        <OwlCarousel
                            className="owl-carousel owl-theme"
                            center={false}
                            items={ 2 }
                            loop={false}
                            margin={15}
                            responsive= {{
                                0: {
                                    items: 1
                                },
                                767: {
                                    items: 3
                                },
                                1000: {
                                    items: 4
                                },
                                1400: {
                                    items: 5
                                }
                            }}
                        >

						{
							jsonItem.items.map((item, i) => {
                                return <div key={ i } className="item">
                                    <a href="adventure-detail.html" className="grid_item_adventure">
                                        <figure>
                                            <div className="score"><strong>7.9</strong></div>
                                            <img src={ item.imagen } className="img-fluid" alt="" />
                                            <div className="info">
                                                <em>{ item.titulo }</em>
                                                <h3>{ item.descripcion }</h3>
                                            </div>
                                        </figure>
                                    </a>
                                </div>;
							})
						}
						</OwlCarousel>
                </section>
			
        
    );
}

export default CarouselAdventure;