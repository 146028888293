import http from '../../http';

const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const getHome = () =>{
    return(dispatch, getState) => {
        // http.post('page/homeslider-page', {accion:""}, vHeader)
        http.post('pages/home/home.php', {accion:""}, vHeader)
        .then(function (response) {
            dispatch({
                type: "HOME_DATA_SUCCESS", payload: {
                    data: response.data.resultado,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'HOME_DATA_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        }); 
    }
}


