import $ from 'jquery';

$(function(){

	$(window).on('load', function () {
		$('body').delay(350);
		$('.hero_in h1,.hero_in form').addClass('animated');
		$('.hero_single, .hero_in').addClass('start_bg_zoom');
		$(window).scroll();
	});
	
	//Scroll to top
	$(window).on('scroll', function () {
		'use strict';
		if ($(this).scrollTop() != 0) {
			$('#toTop').fadeIn();
		} else {
			$('#toTop').fadeOut();
		}
	});
	
	// Sticky nav
	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 1) {
			$('.header').addClass("sticky");
		} else {
			$('.header').removeClass("sticky");
		}
	});
});