import { combineReducers } from 'redux';
import homeReducer from "./homeReducer.js";
import rentReducer from "./rentReducer.js";
import contactReducer from "./contactReducer.js";
import aboutReducer from "./aboutReducer.js";

const rootReducer = combineReducers({
    home:     homeReducer,    
    rent:     rentReducer,    
    contact:  contactReducer,    
    about:    aboutReducer,    
});

export default rootReducer;