import http from '../../http';

const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const getRentDetail = (id) =>{
    return(dispatch, getState) => {
        // http.get('page/rentnow-page/' + id, vHeader)
        http.post('pages/rent/rent.php', { id }, vHeader)
        .then(function (response) {
            dispatch({
                type: "RENT_DATA_SUCCESS", payload: {
                    data: response.data.resultado,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'RENT_DATA_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        }); 
    }
}

export const getRentLuxuryDetail = (id) => {
    return(dispatch, getState) => {
        http.post('pages/rent/luxury_rent.php', { id }, vHeader)
        .then(function (response) {
            dispatch({
                type: "RENT_DATA_SUCCESS", payload: {
                    data: response.data.resultado,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'RENT_DATA_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        }); 
    }
}

export const getListadoRent = () =>{
    return(dispatch, getState) => {
        // http.post('page/rentnow-page', vHeader)
        http.get('pages/rent/list.php')
        .then(function (response){
            dispatch({
                type: "RENT_LIST_SUCCESS", payload: {
                    data: response.data.resultado.rentnow,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'RENT_LIST_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        }); 
    }
}

export const getListadoLuxuryRent = () =>{
    return(dispatch, getState) => {
        // http.post('page/rentnowluxury-page', vHeader)
        http.get('pages/rent/luxury_list.php')
        .then(function (response) {
            dispatch({
                type: "LUXURY_LIST_SUCCESS", payload: {
                    data: response.data.resultado.rentnow,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'LUXURY_LIST_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        }); 
    }
}

export const deleteListadoRent = () => {
    return(dispatch, getState) => {
        dispatch({
            type: "CLEAN_LIST_SUCCESS", payload: { data: null }
        });
    }
}