// import './index.css';
// import App from './App';

// import { HashRouter } from "react-router-dom";


// ReactDOM.render(
//   <Provider store={store}>
//     <HashRouter>
//       <App />
//     </HashRouter>,
//   </Provider>,
//   document.getElementById('root')
  
// );

import React from 'react';
import ReactDOM from 'react-dom';
import store from './store/store';
import { Provider } from 'react-redux';
import { createBrowserHistory } from "history";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css?jj=11';
import './assets/css/vendors.css?jj=11';
import './assets/css/color-navy.css?jj=11';
import './index.css';

import Home from './Components/Home/Home';
import Hotel from './Components/Hotel/Hotel';
import Adventure from './Components/Adventure/Adventure';
import Contacts from './Components/Contacts/Contacts';
import RentNow from './Components/RentNow/RentNow';
import RentNowList from './Components/RentNow/RentNowList';
import LuxuryList from './Components/RentNow/LuxuryList';
import About from './Components/About/About';
import PichBook from './Components/PichBook/PichBook';
import Gallery from './Components/Gallery/Gallery';

import './assets/js/main.js';

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/hotel" component={Hotel} exact />
          <Route path="/adventure" component={Adventure} exact />
          <Route path="/rentlist" component={RentNowList} exact />
          <Route path="/luxurylist" component={LuxuryList} exact />
          <Route path="/contacts" component={Contacts} exact />
          <Route path="/about" component={About} exact />
          <Route path="/rent" component={RentNow} />
          <Route path="/book" component={PichBook} exact />
          <Route path="/gallery" component={Gallery} exact />

          <Route exact path="/" component={Home} />
      </Switch>
    </Router>  
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();