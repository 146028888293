import React from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

import PopupMagnific from '../Shared/PopupMagnific';

const Gallery = () =>{
    return (
        <div id="page">

            <Header />

            <div>
              <section className="hero_in contacts">
                <div className="wrapper">
                  <div className="container">
                    <h1 className="fadeInUp">GALLERY</h1>
                  </div>
                </div>
              </section>
              <div className="row">
                <div className="col-md-12">
                <PopupMagnific />
                </div>
              </div>
            </div>
            <Footer/>            
            
      </div>
        
    );
}

export default Gallery;