import React, {useEffect} from 'react';

import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import SharedRentNow from '../Shared/SharedRentNow';

const RentNowList = () =>{

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div id="page" className="theia-exception">
            <Header />

            <main>
                <section className="hero_in contacts">
                    <div className="wrapper">
                        <div className="container">
                            <h1 className="fadeInUp"><span></span>RENT NOW</h1>
                        </div>
                    </div>
                </section>
                <div className="container margin_60_35">
                    {/* <SharedRentNow titulo="" subtitulo="" tipo="" /> */}
                    <div className="isotope-wrapper">
	                    <div className="row">
                            <SharedRentNow titulo="" subtitulo="" tipo="" />
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default RentNowList;
