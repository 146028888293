import React from 'react';

import video_adventuremp4 from '../../assets/video/adventuree.mp4';

const VideoAdventure = () =>{
    return(
        <>
            <section className="header-video adventure">
                <video autoplay="true" loop="loop" muted id="teaser-video" className="teaser-video" >
                    <source src={video_adventuremp4} type="video/mp4" />
                </video>
            </section>
        </>
    );
}

export default VideoAdventure;